<template>
	<div class="section-8">
		<div class="container-section">
			<div class="box-section">
				<div class="content-section">
					<svg-section-8 />

					<div class="content-text">
						<div class="title">Activitatea echipei<br/><span class="grey">în timp real</span></div>
						<div class="description">Urmărește, în timp real, actualizările de progres, promisiunile zilnice, realizarea de taskuri și mesajele. Stai la curent cu toate lucrurile importante care se întâmplă în companie.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection8 from '../../assets/landing/SvgSection8'

	export default {
		data() {
			return {

			}
		},
		components: {
			SvgSection8
		},
		mounted(){
		}
	}
</script>